import React, { useContext, useRef, useState } from 'react';
import "./contact.css";
import Phone from "../../img/phone.png";
import Email from "../../img/email.png";
import Address from "../../img/address.png";
import emailjs from 'emailjs-com';
import { ThemeContext } from '../../context';

const Contact = () => {
    const formRef = useRef()
    const [done, setDone] = useState(false);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const handleSubmit = (e)=>{
        e.preventDefault();
        emailjs
        .sendForm(
          "service_gqxc4do",
          "template_746z8br",
          formRef.current,
          "user_Eu3mSuhrCzh4g8xpIWzp5"
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true)
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
  return (
      <div className='c'>
        <div className="c-bg"></div>
        <div className="c-wrapper">
            <div className="c-left">
                <h1 className="c-title">Let's discuss your project</h1>
                <div className="c-info">
                    <div className="c-info-item">
                        <img src={Phone} alt="" className="c-icon" />
                        +1 123 456 7890
                    </div>
                    <div className="c-info-item">
                        <img src={Email} alt="" className="c-icon" />
                        contact@emailDOTdev
                    </div>
                    <div className="c-info-item">
                        <img src={Address} alt="" className="c-icon" />
                        789 Mockingbird Lane, Bugtown Banana Republic
                    </div>
                </div>
            </div>
            <div className="c-right">
                <p className="c-desc">
                    <b>Looking for inspiration?</b> Get in touch. Always available for
                    freelancing if the right project comes along.
                </p>
                <form ref={formRef} onSubmit={handleSubmit} >
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Name" name="user_name" />
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subject" name="user_subject" />
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email" />
                    <textarea style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Message" name="message" />
                    <button>Submit</button>
                    {done && "Thank you for your request to contact!"}
                </form>
            </div>
        </div>
    </div>
  )
} 

export default Contact